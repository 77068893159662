.docviz-wrapper {
    .attachment {
        .attachTitle {
            white-space: pre-wrap;
        }
    }
}

div[class*='DocVizSearchStyles__DocVizSearchWrapper'] {
    display: none;
}