@import "src/global-styles/colors";
@import "src/mixins/mixins";

.contribute-knowledge-tab {
  position: relative;

  &__content {
    @include getLayoutInnerPadding();
    background-color: $gray--lightest;
    padding-top: 20px;
    padding-right: 0px;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-right: 140px;
  }

  &__bundle-button {
    border-radius: 5px;
    padding: 10px 30px;
    background-color: $bcg-green--light-blue-green;
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    cursor: pointer;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &__filters {
    padding-bottom: 22px;
  }

  &__category {
    margin-bottom: 12px;
  }

  &__category-title {
    font-size: 12px;
    color: $gray--darkest;
    font-weight: 700;
    letter-spacing: 0%;
    line-height: 20px;
    font-family: $sans-reg;
    vertical-align: middle;

    &-text {
      text-transform: uppercase;
    }
  }

  &__items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__item-box {
    background: $white;
    padding: 8px;
    border: 1px solid $gray--light;
    border-radius: 8px;
    gap: 16px;
    width: 315px;
    height: 99px;
  }

  &__item-title {
    font-size: 14px;
    color: $gray--darkest;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 20px;
    font-family: $sans-reg;
    margin-bottom: 6px;

  }

  &__item-desc {
    font-size: 14px;
    color: $gray--brownish;
    font-family: $sans-reg;
    font-weight: 400;
    letter-spacing: 0px;
    @include multilineellipsis(3);
  }
}